/* latin-ext */
@font-face {
  font-family: "MontserratLight";
  font-style: normal;
  font-display: swap;
  src: url(Montserrat/Montserrat-Light.otf) format("otf"),
  url(Montserrat/Montserrat-Light.otf) format("truetype");
}
@font-face {
  font-family: "MontserratRegular";
  font-style: normal;
  font-display: swap;
  src: url(Montserrat/Montserrat-Regular.ttf) format("ttf"),
  url(Montserrat/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "MontserratSemiBold";
  font-style: normal;
  font-display: swap;
  src: url(Montserrat/Montserrat-SemiBold.otf) format("otf"),
  url(Montserrat/Montserrat-SemiBold.otf) format("truetype");
}

@font-face {
  font-family: "MontserratBold";
  font-style: normal;
  font-display: swap;
  src: url(Montserrat/Montserrat-Bold.otf) format("otf"),
  url(Montserrat/Montserrat-Bold.otf) format("truetype");
}
